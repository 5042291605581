"use strict";
(function() {
    /**
     * Polyfill to remove() element for IE
     */
    if (!Element.prototype.hasOwnProperty("remove")) {
        Object.defineProperty(Element.prototype, "remove", {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                this.parentNode.removeChild(this);
            }
        });
    }

    /**
     * Polyfill for fixing IE supports startWith()
     * source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
     */
    if (!String.prototype.hasOwnProperty("startsWith")) {
        String.prototype.startsWith = function(searchString, position) {
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }

    /**
     * Polyfill for fixing IE supports after()
     * source: https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/after
     */
    if (!Element.prototype.hasOwnProperty("after")) {
        Object.defineProperty(Element.prototype, "after", {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function after() {
                var argArr = Array.prototype.slice.call(arguments),
                    docFrag = document.createDocumentFragment();

                argArr.forEach(function(argItem) {
                    var isNode = argItem instanceof Node;
                    docFrag.appendChild(
                        isNode
                            ? argItem
                            : document.createTextNode(String(argItem))
                    );
                });

                this.parentNode.insertBefore(docFrag, this.nextSibling);
            }
        });
    }
})();
